.customer-search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
}

.search-bar {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
}

.search-bar input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 200px;
}

.search-bar button {
    background-color: #333;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.search-bar button:hover {
    background-color: #555;
}

.customer-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    text-align: center;
    font-family: Arial, sans-serif;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    margin-bottom: 10px;
}

.customer-header {
    display: flex;
    justify-content: space-between;
    background-color: #333;
    color: #fff;
    padding: 8px;
    border-radius: 6px 6px 0 0;
}

.customer-info h2 {
    margin: 8px 0;
    font-size: 1.5em;
}

.customer-info p {
    color: #555;
    font-size: 0.9em;
}

.error {
    color: red;
    margin: 10px 0;
}
