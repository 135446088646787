/* General container styling */
.sms-container {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f8f8f8;
}

.sms-wrapper {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

/* Header styling */
.sms-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.sms-header img {
    height: 50px;
}

.sms-header h1 {
    font-size: 18px;
    color: #333;
    margin: 0;
}

/* Tabs */
.sms-tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.sms-tabs a {
    text-decoration: none;
    font-size: 15px;
    color: #888;
    padding: 10px 20px;
    border-radius: 5px;
    transition: 0.3s;
}

.sms-tabs a.active {
    background-color: #007bff;
    color: #fff;
    font-weight: bold;
}

/* Search bar */
.sms-search-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
}

.sms-search-bar input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.sms-search-bar .sms-icon {
    position: absolute;
    left: 10px;
    color: #aaa;
    font-size: 18px;
}

/* SMS item */
.sms-item-top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.sms-item {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
    background: #fdfdfd;
    transition: 0.3s;
}

.sms-item:hover {
    background: #f7faff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

.sms-item h3 {
    font-size: 16px;
    color: #333;
    margin: 0 0 5px;
    font-weight: bold;
}

.sms-item span {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
}

.sms-item p {
    font-size: 14px;
    color: #555;
    margin: 0;
    line-height: 1.5;
}

.sms-item .sms-type {
    color: #007bff;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 5px;
}

/* Pagination */
.sms-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.sms-pagination button {
    padding: 10px 15px;
    font-size: 14px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

.sms-pagination button:hover {
    background: #0056b3;
}

.sms-pagination button:disabled {
    background: #ddd;
    cursor: not-allowed;
}

.sms-pagination span {
    font-size: 14px;
    color: #555;
}

/* receipt css*/

.receipt {
    border: 1px solid #ccc;
    padding: 20px;
    width: 600px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.receipt-section-top-head{
    display: flex; 
    flex-direction: row; 
    justify-content:center;
    align-items:center;
    border-top:1px solid #ddd;
    padding: 0 10px;
}   
.receipt-section-top {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    align-items:center;
    border-bottom: 1px solid #ddd;
    padding: 0 10px;
}
.receipt-section-bill {
    display: flex; 
    flex-direction: row; 
    justify-content: space-between;
    align-items:center;
    border-bottom: 1px solid #ddd;
    padding: 0 10px;
}

.receipt-section-bill-title{
    font-weight: bold;
    color:#3C9CD7;
    font-size: 1.1rem;
} 

.receipt-section-bill-value{
    font-weight: bold;
    color:#333;
    font-size: 1.0rem;
} 

.receipt-section-payment {
    display: flex; 
    flex-direction: row; 
    justify-content: space-between;
    align-items:center;
    border-bottom: 1px solid #ddd;
    padding: 0 10px;
}

.payment-receipt-status {
    text-align: center;
    color: #003366;
    background-color: #d9edf7;
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
}


.receipt-div{
    max-width: 600px;
    margin: auto;
    padding: 10px 5px;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 8px;
    word-wrap: break-word; /* Prevent text from overflowing */
    overflow: hidden; /* Hide any overflowing content */
}
/**/



.customer-info h2 {
    margin: 0;
    font-size: 18px;
}

.customer-info p {
    margin: 2px 0;
    font-size: 14px;
    color: #555;
}

.address {
    margin: 10px 0;
    font-size: 14px;
    color: #777;
}

.payment-details {
    font-size: 14px;
}

.row-unpaid {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
}

.highlight {
    font-weight: bold;
    color: #007bff;
}

.unpaid {
    font-weight: bold;
    color: #EC2227;
}

.recept-footer {
    margin-top: 20px;
    font-size: 12px;
    color: #555;
}
