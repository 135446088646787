
.modal-header {
    padding: 10px !important;
}
.payment-collection-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    color: #fff;
    padding: 10px;
    font-weight: bold;
    border-radius: 8px 8px 0 0;
}
.payment-collection-header div {
    text-align: center;
}

.bottom-section {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.sticky {
    position: sticky;
    left: 0;
    background-color: #f3f3f3; /* Adjust as needed */
    z-index: 1; /* Ensures it stays on top of other cells */
}
.remarks {
    width: 100%;
    height: 60px;
    margin-top: 10px;
    padding: 8px;
    border: 1px solid #333;
    border-radius: 4px;
}
.checkbox-group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 10px;
}
.receive-button {
    display: block;
    width: 100%;
    padding: 12px;
    background-color: #333;
    color: #fff;
    text-align: center;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    text-decoration: none;
}